import React, { useEffect, useState, ReactNode, useCallback } from 'react';
import { setXResponse } from '../../utils/common';
import _ from 'lodash';
interface ReCaptchaComponentProps {
  children: ReactNode;
  onScriptLoad?: () => void;
  resetTrigger?: number | boolean;
}

const ReCaptchaComponentV3: React.FC<ReCaptchaComponentProps> = ({ 
  children, 
  onScriptLoad,
  resetTrigger = 0 
}) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [scriptLoadPromise, setScriptLoadPromise] = useState<Promise<void> | null>(null);

  const loadReCaptchaScript = useCallback(() => {
    if (scriptLoadPromise) {
      return scriptLoadPromise;
    }

    const loadPromise = new Promise<void>((resolve, reject) => {
      const existingScript = document.querySelector('script[src*="recaptcha"]');
      if (existingScript) {
        existingScript.remove();
      }

      if (window.grecaptcha) {
        delete window.grecaptcha;
      }

      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_KEY_V3}`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        // small delay to ensure grecaptcha is fully initialized
        setTimeout(() => {
          window.grecaptcha?.ready(() => {
            console.log('reCAPTCHA is ready');
            setIsScriptLoaded(true);
            onScriptLoad?.();
            resolve();
          });
        }, 100);
      };

      script.onerror = (error) => {
        setScriptLoadPromise(null);
        reject(error);
      };

      document.head.appendChild(script);
    });

    setScriptLoadPromise(loadPromise);
    return loadPromise;
  }, [onScriptLoad]);

  // const cleanupReCaptcha = useCallback(() => {
  //   const script = document.querySelector('script[src*="recaptcha"]');
  //   if (script) {
  //     script.remove();
  //   }
  //   if (window.grecaptcha) {
  //     delete window.grecaptcha;
  //   }
  //   setIsScriptLoaded(false);
  //   setScriptLoadPromise(null);
  //   setIsProcessing(false);
  //   setIsVerifying(false);
  // }, []);

  const cleanupReCaptcha = useCallback(() => {
    setIsScriptLoaded(false);
    setScriptLoadPromise(null);
    setIsProcessing(false);
    setIsVerifying(false);
    // Only remove script if we're truly resetting
    if (resetTrigger) {
      const script = document.querySelector('script[src*="recaptcha"]');
      if (script) {
        script.remove();
      }
      if (window.grecaptcha) {
        delete window.grecaptcha;
      }
    }
  }, [resetTrigger]);

   // Add CSS to reposition the reCAPTCHA badge
   useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .grecaptcha-badge {
        position: fixed !important;
        right: 0 !important;
        bottom: 14px !important;
        z-index: 9999 !important; /* Increased z-index */
        transform: translateX(188px);
        transition: transform 0.3s ease, opacity 0.3s ease !important;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2) !important;
         
      }
  
      .grecaptcha-badge:hover {
        transform: translateX(0px) !important;
      }
  
      .grecaptcha-badge * {
        pointer-events: auto !important;
      }
      .recaptcha-wrapper {
        position: relative !important;
        z-index: 9999 !important;
      }
      .MuiDivider-root {
        z-index: 1 !important;
        position: relative !important;
      }
    `;
    document.head.appendChild(style);
  
    return () => {
      style.remove();
    };
  }, []);

  useEffect(() => {
    cleanupReCaptcha();
    
    const timeoutId = setTimeout(() => {
      loadReCaptchaScript().catch((error) => {
        console.error('Failed to load reCAPTCHA:', error);
        setScriptLoadPromise(null);
      });
    }, 200);

    return () => {
      clearTimeout(timeoutId);
      cleanupReCaptcha();
    };
  }, [resetTrigger, loadReCaptchaScript, cleanupReCaptcha]);

const handleCaptchaVerification = async () => {
    if (isProcessing) return null;
    
    setIsVerifying(true);
    setIsProcessing(true);

    try {
      if (!window.grecaptcha) {
        await loadReCaptchaScript();
      }

      const token = await new Promise<string>((resolve, reject) => {
        const executeRecaptcha = async () => {
          try {
            const token = await window.grecaptcha.execute(
              process.env.RECAPTCHA_KEY_V3 || '',
              { action: 'sms_request' }
            );
            resolve(token);
          } catch (error) {
            reject(error);
          }
        };

        if (window.grecaptcha?.ready) {
          window.grecaptcha.ready(executeRecaptcha);
        } else {
          setTimeout(executeRecaptcha, 100);
        }
      });

      localStorage.setItem('x-preset-token', token);
      setXResponse(token);

      return token;
    } catch (err) {
      console.error('ReCAPTCHA verification failed:', err);
      throw err;
    } finally {
      setIsVerifying(false);
      setTimeout(() => {
        setIsProcessing(false);
      }, 300);
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onClick: async (e: React.MouseEvent) => {
          e.preventDefault();
          if (!isProcessing) {
            try {
              await handleCaptchaVerification();
              if (child.props.onClick) {
                await child.props.onClick(e);
              }
              if (child.props.type === 'submit') {
                const form = (e.target as HTMLElement).closest('form');
                if (form) {
                  const submitEvent = new Event('submit', {
                    cancelable: true,
                    bubbles: true,
                  });
                  form.dispatchEvent(submitEvent);
                }
              }
            } catch (error) {
              console.error('ReCAPTCHA verification failed:', error);
            }
          }
        },
        disabled: isVerifying || !isScriptLoaded || child.props.disabled || isProcessing,
      });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};

export default ReCaptchaComponentV3;