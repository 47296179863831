import * as React from 'react'
// import Drawer from '@mui/material/Drawer'
// import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import Link from '@mui/material/Link'
import Images from '../../utils/images'
import Icons from '../../utils/icons'
import './header.scss'
import { RootState } from '../../reducers'
import { ReducerModel } from '../../reducers/reducerModel'
import { useDispatch, useSelector } from 'react-redux'
import { decryptData, getLocalUserDetails, setUserToken, tagMethodGTM } from '../../utils/common'
import UserActions from '../../actions/userActions'
import { UserDetails } from '../../constants/model'
import { useHistory } from 'react-router-dom'
import { matchPathname } from '../../utils/routes'
import userActions from '../../actions/userActions'
import TopBanner from '../Banner/TopBanner'
import { useMediaQuery } from '@mui/material'

export default function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [showHeaderActiveClass, setHeaderActiveClass] = React.useState(false)

  const {currentUserDetails} = useSelector(
    (store: ReducerModel) => store.userDetails
  )

  const { userToken, userName, firstName,imageBase64 } = React.useMemo(() => {
    return decryptData(currentUserDetails)
  },[currentUserDetails])

  
  const dispatch: Function = useDispatch()
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const onLogoutClick = () => {
    dispatch(
      userActions.userLogout(
        (res: any) => {
          console.log({ res })
        },
        () => { }
      )
    )
    setUserToken(new UserDetails())
    dispatch(UserActions.updateUserDetails(new UserDetails()))
    history.replace('/')
    window.location.reload()
  }

  const [openLogout, setOpenLogout] = React.useState(false)
  const handleClickOpen = () => {
    setOpenLogout(true)
  }
  const handleClose = () => {
    setOpenLogout(false)
  }
  // const userData = useSelector((store: RootState) => store.userDetails)

  const history = useHistory()
  const isMyAccountActive = history.location.pathname.includes('my-account')
  const isProductPageActive =
    history.location.pathname.replace('/motorbike', '').length == 0
  const isProductPage = history.location.pathname.includes('motorbike')
  const isHyperLocalPage = history.location.pathname.includes('electric-motorbike/ahmedabad')
  const isLegalPage = history.location.pathname.includes('terms-and-conditions') || 
                      history.location.pathname.includes('privacy-policy') ||  
                      history.location.pathname.includes('online-booking-terms') ||  
                      history.location.pathname.includes('caution-notice') 
  const isMenuBlack = history.location.pathname.includes('register-your-interest') || history.location.pathname.includes('become-a-partner') || history.location.pathname.includes('motorbike')
  const isMobile = useMediaQuery('(max-width:576px)');
  
  React.useEffect(() => {
    //let headerOne = document.getElementById('header1')
    //let headerTwo = document.getElementById('header2')
    const target1 = document.querySelector('#hero-sec')    
    const compTarget = document.querySelector('#compatiblitySec')    
    const target3 = document.querySelector('#whiteHeader2')
    const footerTarget = document.querySelector('#footerSec')    

    document.addEventListener('scroll', function () {
      if (
        compTarget &&
        target1 &&
        window.scrollY < compTarget.offsetTop &&
        window.scrollY >= target1.offsetTop
      ) {
        // headerOne?.classList.add('activeHeader')
        // headerOne?.classList.remove('lite-header')
        // headerTwo?.classList.remove('lite-header')
        // headerTwo?.classList.remove('activeHeader')                
      } else if (
        target3 &&
        footerTarget &&
        target3.offsetTop - 80 <= window.scrollY &&
        window.screenY <= footerTarget.offsetTop - 80
      ) {
        // headerOne?.classList.remove('activeHeader')
        // headerOne?.classList.add('lite-header')
        // headerTwo?.classList.remove('activeHeader')
        // headerTwo?.classList.add('lite-header')        
      } else {        
        setHeaderActiveClass(false)
      }
    })
  }, [])
  React.useEffect(() => {
    function controlClick(e) {
      if(e.target.id !== 'hamclose'){
        setHeaderActiveClass(false)
      }
    }
    document.body.addEventListener('click', controlClick);
    return () => {
      document.body.removeEventListener('click', controlClick);
    };
  }, []);
    return (
    <>
      <header id="header1">
        {/* <TopBanner /> */}
        <div className={`header-content ${(userToken && !isMyAccountActive) ? 'normalHeight': ''} ${isProductPage ? 'for-product-page' : ''}${ isHyperLocalPage ? 'for-hyperlocal-page' : ''}`}>
          <div className="header-content-inner flex al-center js-between">
            <Link aria-label="Matter" onClick={() =>{ 
              // console.log("click");
              tagMethodGTM("Home Logo" , "Matter Home Logo" , "Click" , "MATTER")
              history.push('/')
              }}>
              <img src={Images.Logo} alt="Matter Black Logo" className='logo-img' />
            </Link>
            <nav className="links-list flex al-center js-end">
            <ul
              className={`${isProductPage ? 'for-product-page' : ''} ${isLegalPage ? 'for-legal-page' : ''} sidemenu ${showHeaderActiveClass ? 'active' : ''} `}
              id="dropDown_list_UL"
            >
              <div className='tiled-box'></div>
              <li
                onClick={() => {
                  tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Who we are")
                  history.push('/who-we-are')
                }}
                id="whoweare"
              >
                <h5 className='monument'>Who we are</h5>
              </li>
              <li
                onClick={() => {
                  tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Innovation Hub")
                  history.push('/innovation-hub')
                }}
                id="innovationhub"
              >
                <h5 className='monument'>Innovation Hub</h5>
              </li>              
              <li
                onClick={() => {
                  tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Matter Stream")
                  history.push('/matter-stream')
                }}
                id="matterstream"
              >
                <h5 className='monument'>Matter Stream</h5>
              </li>
              <li
                onClick={() => {
                  //tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Matter Stream")
                  history.push('/tco-calculator')
                }}
                id="tcocalculator"
              >
                <h5 className='monument'>Calculate Savings</h5>
              </li>
              {/* <li
                onClick={() => {
                  window.open('http://4.240.70.135:8080/', '_blank')
                }}
                id="matterCommunity"
              >
                <h5 className='monument'>Matter Community</h5>
              </li>               */}
              {isMobile ? <li
                onClick={() => {
                  //tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Find a Dealer")
                  history.push('/ec-booking')
                }}
                id="ecbooking"
              >
                <h5 className='monument'>Book Test Ride</h5>
              </li> : ''}
              <li
                onClick={() => {
                  tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Find A Dealer")
                  history.push('/dealer-locator')
                }}
                id="delaer"
              >
                <h5 className='monument'>Find A Dealer</h5>
              </li>
              <li
                onClick={() => {
                  tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Partner With Us")
                  history.push('/partner-with-us')
                }}
                id="partnerwithus"
              >
                <h5 className='monument'>Partner With Us</h5>
              </li>
              <li
                onClick={() => {
                  tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Support")
                  history.push('/support')
                }}
                id="support"
                className={`li-support`}
              >
                <h5 className='monument'>Support</h5>
              </li>
              <li
                id="footer-text">
                  <hr className='hr' />
                  <div className='footer-area'>
                    <ul className='top'>
                      <li>
                        <Link
                          onClick={() => {
                            tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Caution Notice")
                            history.push('/caution-notice')
                          }}
                        >
                          Caution Notice
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Privacy Policy")
                            history.push('/privacy-policy')
                          }}
                          >
                          Privacy Policy
                        </Link>
                        </li>
                      <li>
                        <Link
                          onClick={() => {
                            tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Test Ride T&C")
                            history.push('/test-ride-terms-and-conditions')
                          }}
                          >
                          Test Ride T&C
                        </Link>
                        </li>

                         <li>
                        <Link
                          onClick={() => {
                            tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Join Waitlist T&C")
                            history.push('/terms-and-conditions-joinwaitlist')
                          }}
                        >
                          Join Waitlist T&C
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "T & C of Use")
                            history.push('/terms-and-conditions')
                          }}
                        >
                          T & C of Use
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Booking T & C")
                            history.push('/online-booking-terms')
                          }}
                        >
                          Booking T & C
                        </Link>
                      </li>
                    </ul>
                    <div className='bottom'>
                      <p>
                      &#169; {new Date().getFullYear()}. Matter Motor Works Pvt. Ltd. All rights reserved
                      </p>
                    </div>
                  </div>
              </li>
            </ul>
              {/* {isMobile ? '' : <Link
                variant="caption"
                onClick={() => {
                  // tagMethodGTM("Top Navigation Menu" , "Matter Top Navigation Menu" , "Click" , " Explore Matter Aera")
                  history.push('/tco-calculator')
                }}
                className={`vietnam ${isProductPageActive ? 'd-none' : ''}`}
              >
                Calculate Savings
              </Link>} */}
              {isMobile ? '' : <Link
                variant="caption"
                onClick={() => {
                  // tagMethodGTM("Top Navigation Menu" , "Matter Top Navigation Menu" , "Click" , " Explore Matter Aera")
                  history.push('/ec-booking')
                }}
                className={`vietnam ${isProductPageActive ? 'd-none' : ''}`}
              >
                Book Test Ride
              </Link>}
              <Link
                variant="caption"
                // target="_blank"
                // href="/motorbike"
                onClick={() => {
                  tagMethodGTM("Top Navigation Menu" , "Matter Top Navigation Menu" , "Click" , " Explore Matter Aera")
                  history.push('/motorbike')
                }}
                className={`vietnam ${isProductPageActive ? 'd-none' : ''}`}
              >
                MATTER AERA
              </Link>
              {!userToken ? (
                <Link
                  onClick={() => {
                    tagMethodGTM("Top Navigation Menu" , "Matter Top Navigation Menu" , "Click" , " Login")
                  
                    history.push('/login')
                  }}
                  variant="caption"
                  className="vietnam"
                >
                  Login
                </Link>
              ) : (
                <>
                  {isMyAccountActive ? (
                    <Link
                      onClick={() => {
                        tagMethodGTM("Login" , `Matter - ${userName}` , "Click" , "Logout");
                        handleClickOpen()}}
                      variant="caption"
                      className="vietnam"
                    >
                      Logout
                    </Link>
                  ) : (
                    <Link
                      onClick={() => history.push('/my-account')}
                      variant="subtitle2"
                      className="monument"
                      title={`Hi ${firstName}, click here to see your account`}
                    >
                      <span className='username'>{userName}</span>
                      <div className="profile-img-cover">
                        <img src={imageBase64 || Images.DummyUser} alt="" />
                      </div>
                    </Link>
                  )}
                </>
              )}

              {/* <IconButton
                color="inherit"
                aria-label="Click to open main menu"
                onClick={handleDrawerToggle}
                className="menu-icon"
              >
                <img src={Icons.HamburgerIconBlack} alt="logo" />
              </IconButton> */}
              {/* {isProductPage ? <></> :  */}
              <button
                onClick={() => {
                  setHeaderActiveClass(!showHeaderActiveClass)
                }}
                className={`nav-ham-icon ${showHeaderActiveClass ? 'open' : ''}`}
              >
                {showHeaderActiveClass ?
                <img id="hamclose" className='ic-ham-close' src={Icons.HamMenuCloseIcon} />
                : <img className={`ham-img ${isMenuBlack ? 'black' : ''}`} src={Icons.HamMenuIcon} />
                }
              </button>
              {/* } */}
              {/* <button
                onClick={() => {
                  setHeaderActiveClass(!showHeaderActiveClass)
                }}
                className={`ham-btn`}
              >
                <span>
                  <i className=""></i>
                </span>
                <span>
                  <i className=""></i>
                </span>
              </button> */}
            </nav>
          </div>
        </div>
      </header>
      {/* <Drawer
        className="menu-drawer"
        open={mobileOpen}
        anchor="right"
        onClose={handleDrawerToggle}
      >
        <>
          <IconButton
            color="inherit"
            aria-label="Click to close main menu"
            onClick={handleDrawerToggle}
            className="close-menu-icon"
          >
            <img src={Icons.CloseWhiteEdgeIcon} alt="logo" />
          </IconButton>
          <nav>
            <Link variant="body2">Innovation Hub</Link>
            <Link variant="body2">Matter Motorbike</Link>
            <Link variant="body2">Contact Us</Link>
            <Link variant="body2">Careers</Link>
            <Link variant="body2">About us</Link>
            <Link variant="body2">Our Culture</Link>
            <Link variant="body2">Partner With Us</Link>
          </nav>
        </>
      </Drawer> */}

      <Dialog
        open={openLogout}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="confirmation-dialog black-theme-dialog"
        fullWidth
        maxWidth="sm"
      >
        <img src={Icons.BigRedInfoIcon} className="info-icon" alt="" />
        <h5 className="monument mb-8 white">Sure to Log Out?</h5>
        <p className="small subTextLight mb-40 mb-xs-20">
          Hey Rider, you're going to leave behind your Configurations, Future
          Launches and the chance to ride Matter's next-generation bike in a
          click. Sure you want to Log out?
        </p>
        <Grid container spacing={{ xs: 2, lg: 3 }}>
          <Grid item xs={12} sm={6}>
            <button
              className="btn w-100 btn-black-cancel"
              onClick={handleClose}
            >
              Cancel
            </button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <button className="btn btn-white w-100" onClick={() => {
               tagMethodGTM("Login" , `Matter - ${userName}` , "Click" , "Logout");
              onLogoutClick()
              }}>
              Logout
            </button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}
