import React from 'react'
import Header from '../../components/Header'
import './legal.scss'
import Footer from '../../components/Footer'
import { useEffect } from 'react'
import HeadlessComponent from '../../components/HeadlessComponent'
import { useDispatch, useSelector } from 'react-redux'
import homeActions from '../../actions/homeActions'
import MarkDown from '../../components/Markdown'
import { ReducerModel } from '../../reducers/reducerModel'

const JoinWaitlistTC = () => {
  const dispatch: Function = useDispatch()
  useEffect(() => {    
    window.addEventListener('click', (e) => {
      e.preventDefault()
      window.open(e.target?.href,"_self")
    })
    document.title = 'TERMS AND CONDITIONS OF JOIN THE WAITLIST | Matter'
    dispatch(
      homeActions.getJoinWaitlist(
        () => {},
        () => {}
      )
    )
  }, [])

  const { joinWaitlistTC } = useSelector((store: ReducerModel) => store.home)
  return (
    <div className="legal-cover">
      <Header />
      {joinWaitlistTC ? (
        <div className="legal-content">
          <h1 className="visuallyHidden">
            TERMS AND CONDITIONS OF JOIN THE WAITLIST
          </h1>
          <HeadlessComponent
            title="Terms & Conditions to Join the Waitlist | MATTER"
            conicalLink="https://www.matter.in/terms-and-conditions-joinwaitlist/"
            description="Explore the terms and conditions for joining the waitlist. Stay informed about the guidelines before signing up for exclusive early access. Book Now!"
          />

          <h2 className="heading monument mb-40 mb-xs-30">
            <MarkDown string={joinWaitlistTC.title} />
          </h2>
          <MarkDown string={joinWaitlistTC.introduction} />
          <br />

          <ul className='join-waitlist-order-list'>
            {joinWaitlistTC.listContent.map((item) => (
              <li key={item.title} className="monu-count">
                <h5 className="monument mb-20">
                  {<MarkDown string={item.title} />}
                </h5>
                <MarkDown string={item.description} />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <Footer />
    </div>
  )
}

export default JoinWaitlistTC
