import { useSelector } from 'react-redux'
import { API_SUCCESS_CODE } from '../../api/constant'
import { endpoints } from '../../api/endPoints'
import Api from '../../api/index'
import { ActionTypes } from '../../constants'
import {  ECBookingDetails, ECBookingFromStrapi, LocatorAndScheduling, VisitDetails } from '../../constants/model'
import locatorAndScheduling from '../../reducers/locatorAndScheduling'
import { ReducerModel } from '../../reducers/reducerModel'
import { distance, getUserLocation, getUserToken } from '../../utils/common'
import axios from 'axios'

const dummyGetList = [
  {
    city: 'Haryana',
    dealerList: [
      {
        dealerName: 'Place 1',
        dealerAddress: '123 Main Street, City, Country',
        state: 'Narnaul',
        latitude: 28.0688,
        longitude: 76.1068,
        distance: '9.2 kms',
        phone: '+91 9997803874',
        pincode: '380009',
      },
      {
        dealerName: 'Place 2',
        dealerAddress: '456 Elm Street, Town, Country',
        state: 'Gurgaon',
        latitude: 28.4595,
        longitude: 77.0266,
        distance: '4.2 kms',
        phone: '+91 9997803874',
        pincode: '380009',
      },
      {
        dealerName: 'Place 3',
        state: 'Ambala',
        latitude: 30.361,
        longitude: 76.8485,
        distance: '7.2 kms',
        phone: '+91 9997803874',
        pincode: '380009',
        dealerAddress: '789 Oak Avenue, Village, Country',
      },
    ],
  },

  {
    city: 'Ahmedabad',
    dealerList: [
      {
        dealerName: 'Matter Motor Works Pvt Ltd',
        dealerAddress:
          'vijay Cross Roads, Navarangpura, Ahmedabad, Gujarat 380009',
        state: 'Gujarat',
        pincode: '380009',
        phone: '7984774653',
        latitude: 23.0418154,
        longitude: 72.550444,
        distance: '7.2 kms',
      },
    ],
  },
]

export const dummyPlacesData = [
  {
    dealerName: 'Place 1',
    address: '123 Main Street, City, Country',
    state: 'Gujarat',
    latitude: 28.7041,
    longitude: 77.1025,
    distance: '9.2 kms',
    contactNo: '+91 9997803874',
  },
  {
    dealerName: 'Place 2',
    address: '456 Elm Street, Town, Country',
    state: 'Gujarat',
    latitude: 19.076,
    longitude: 72.8777,
    distance: '4.2 kms',
    contactNo: '+91 9997803874',
  },
  {
    dealerName: 'Place 3',
    state: 'Gujarat',
    latitude: 22.6708,
    longitude: 71.5724,
    distance: '7.2 kms',
    contactNo: '+91 9997803874',
    address: '789 Oak Avenue, Village, Country',
  },
]

const getDealerList = (place?: any, successCallback?: Function, errorCallback?:Function) => {
  return (dispatch: Function, getState: Function) => {
    const store: ReducerModel = getState()
    const { dealerLocator } = store.locatorAndScheduling

    let userLocation = {
      userLatitude: 0,
      userLongitude: 0,
    }
    
    getUserLocation((result: any) => {
      if (result) {
        const { latitude, longitude } = result
        
        userLocation = {
          userLatitude: latitude,
          userLongitude: longitude,
        }
      }
    })

    Api.getApiCall(
      endpoints.dealerLocator.getDealerList,
      `${place ? '?city=' + place : ''}`,
      (response: any) => {
        if (response) {
          const { error, responseCode, data, message } = response
          if (!error && responseCode == API_SUCCESS_CODE.success) {
            const filterData = data.dealersWithCityList.map((dealer) => {
              let filter = dealer.dealerList.map((item, index) => {                
                const dealerDistance = distance(userLocation.userLatitude, userLocation.userLongitude, item.latitude, item.longitude)

                return {
                  lat: item.latitude,
                  lng: item.longitude,
                  id: item.dealerName + index,
                  dealerName: item.dealerName,
                  distance: dealerDistance,
                  contactNo: item.phone,
                  dealerAddress: item.address,
                  pinCode: item.pincode,
                  state: item.state,
                  whatsAppNo: item.whatsAppNo,
                  dealerCode: item.dealerCode
                }
              })
              return { ...dealer, dealerList: filter }
            })
            dispatch({
              type: ActionTypes.DEALER_LIST,
              payload: {
                dealerLocator: {
                  ...dealerLocator,
                  allDealersList: filterData || [],
                },
              },
            })
            successCallback && successCallback(filterData);
          } else {
            console.log('No data foun derr')
            dispatch({
              type: ActionTypes.DEALER_LIST,
              payload: {
                dealerLocator: {
                  ...dealerLocator,
                  allDealersList: [],
                },
              },
            })
            errorCallback && errorCallback([])
          }
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.DEALER_LIST,
          payload: {
            dealerLocator: {
              ...dealerLocator,

              allDealersList: [],
            },
          },
        })
      }
    )
  }
}
const getDealersForHomeTestRidePin = (success: Function, error: Function, queryParams = {}) => {
  return async (dispatch: Function, getState: Function) => {
    const url = 'https://matter-backend-integration-service.azurewebsites.net/api/getDealersForHomeTestRidePin';
    const headers = {
      'x-functions-key': '3lUO6xKeNjcJN6hQ3VILeLOzOCysn3vWTnRHLF399uYxAzFuHhBPgw==',
    };
    const store: ReducerModel = getState()
    const { dealerLocator } = store.locatorAndScheduling

    try {
      const response = await axios.get(url, { headers, params: queryParams });
      const { status } = response;

      if (status === API_SUCCESS_CODE.success && response.data.responseCode == API_SUCCESS_CODE.success) {
        const dealersWithCityList = response.data.data.dealersWithCityList;
        
        // Flatten dealer details into a single array without nested city or dealer list objects
        const filterData = dealersWithCityList.flatMap((dealer: any) => 
          dealer.dealerList.map((item: any) => ({
            contactNo: item.phone,
            longitude: item.longitude,
            dealerName: item.dealerName,
            distance: item.distance || '8.8',
            latitude: item.latitude,
            dealerAddress: item.address,
            dealerCode: item.dealerCode
          }))
        );
        

        dispatch({
          type: ActionTypes.DEALERS_LIST,
          payload: {
            dealerLocator: {
              ...dealerLocator,
              DealersList: filterData, // Directly store this flattened array
            },
          },
        });

        if (success) success(filterData);
      } else {
        dispatch({
          type: ActionTypes.DEALERS_LIST,
          payload: {
            dealerLocator: {
              ...dealerLocator,
              DealersList: [], // Directly store this flattened array
            },
          },
        })
        dispatch({
          type: ActionTypes.EC_BOOKING,
          payload: {
            errormsg:true
          },
        });
      }
    } catch (error) {
      console.error('Error fetching dealers:', error);

      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          errormsg:true
        },
      });
    }
  };
};


const postDealerList = (payload: any, success: Function, fail: Function) =>
  // payload: any,
  // success: Function,
  // fail: Function
  {
    return (dispatch: Function, getState: Function) => {
      // TODO - Get Dealer list api integration
      const store: ReducerModel = getState()
      const { dealerLocator } = store.locatorAndScheduling
      dispatch({
        type: ActionTypes.DEALER_LIST,
        payload: {
          dealerLocator: {
            ...dealerLocator,
            allDealersList: payload
              ? dummyPlacesData.map((item, index) => ({
                  lat: item.latitude,
                  lng: item.longitude,
                  id: item.dealerName + index,
                  dealerName: item.dealerName,
                  distance: item.distance,
                  contactNo: item.contactNo,
                  dealerAddress: item.address,
                }))
              : [],

            getApiData: dummyGetList,
          },
        },
      })
    }
  }

  const postECBooking = (payload: any, success: Function, fail: Function) =>  
  {
    return (dispatch: Function, getState: Function) => {        
      console.log('Before API Call');    
      Api.postApiCall(
        endpoints.ecBooking.postECBooking,
        payload,
        (response: any) => {
          console.log("visit id" +response.data.visitID)
          const { responseCode, error, message, result } = response;
          if (responseCode === API_SUCCESS_CODE.success) {
            success(responseCode);
            
            dispatch({
              type: ActionTypes.EC_BOOKING,
              payload: {
                visitID:response.data.visitID,
                confirmBtnBookingDetails: true,
                continueBtn: true
              }
            });
          }
        },
        (error: any) => {
          fail(error)
          dispatch({
            type: ActionTypes.EC_BOOKING,
            payload: {
              errorPage: true,
              continueBtn: true,
              errorMsg: error.data.message
            }
          })
        }     
      )
    }
  }
  const confirmBookingDetailsAction = () => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          confirmBtnBookingDetails: false
        }
      })
    }
  }

  const putECBooking = (visitID: string, payload: any, success: Function, fail: Function) =>  
  {
    return (dispatch: Function, getState: Function) => {      
      Api.putApiCall(
        endpoints.ecBooking.postECBooking + `?visitID=${visitID}`,
        payload,
        (response: any) => {
          const { responseCode, error, message, data } = response          
          if (responseCode == API_SUCCESS_CODE.success) {            
            success(responseCode)
            dispatch({
              type: ActionTypes.EC_BOOKING,
              payload: {
                confirmRescheduleBtnEnterDetails: true
              }
            })
          }
        },
        (error: any) => {
          fail(error)
          dispatch({
            type: ActionTypes.EC_BOOKING,
            payload: {
              errorPageReschedule: true,
              errorMsg: error.data.message
            }
          })
        }
      )
    }
  }

  const getECBookings = (payload?: any, success?: Function, fail?: Function) =>  
  {
    return (dispatch: Function, getState: Function) => {          
    Api.getApiCall(
      endpoints.ecBooking.getECBookings,
      '',
      (response: any) => {
        if (response) {
          const { error, responseCode, data, message } = response          
          if (!error && responseCode == API_SUCCESS_CODE.success) {
          const ecBookingDetails : Array<ECBookingDetails> = data.map((item: any) => {            
            return {
              dealerCode: item.dealerCode,
              dealerName: item.dealerName,
              visitDate: item.visitDate,
              day: item.day,
              slotTime: item.slotTime,
              status: item.status,
              visitID: item.visitID              
            }
            
          })
          dispatch({
            type: ActionTypes.EC_BOOKINGS_LIST,
            payload: {
              ecBookingsList: ecBookingDetails
            },
          })
          } else {
            dispatch({
              type: ActionTypes.EC_BOOKINGS_LIST,
              payload: {
                ecBookingsList: []
              },
            })
          }
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.EC_BOOKINGS_LIST,
          payload: {
            ecBookingsList: []
          },
        })
      }
    )
    }
  }

  const getECBookingDetail = (visitID: string, payload?: any, success?: Function, fail?: Function) =>  
  {
    return (dispatch: Function, getState: Function) => {          

    Api.getApiCall(
      endpoints.ecBooking.getECBookingDetail + `?visitID=${visitID}`,
      '',
      (response: any) => {
        if (response) {
          console.log(response)
          const { error, responseCode, data, message } = response         

          if (!error && responseCode == API_SUCCESS_CODE.success) {
          const visitDetails : VisitDetails = {                        
              dealer_name: data.dealerName,
              visitDate: data.visitDate,
              address: data.address,
              dealer_phone: data.dealerPhone,
              slotTime: data.slotTime,
              status: data.status,
              latitude: data.latitude,
              longitude: data.longitude,
              visitID: data.visitID,
              dealerCode: data.dealerCode
          }
            dispatch({
              type: ActionTypes.EC_BOOKING_DETAILS,
              payload: {
                visitDetails: visitDetails
              },
            })
          } else {
            dispatch({
              type: ActionTypes.EC_BOOKING_DETAILS,
              payload: {
                visitDetails: {}
              },
            })
          }
        }
      },
      (error: any) => {
        dispatch({
          type: ActionTypes.EC_BOOKING_DETAILS,
          payload: {
            visitDetails: {}
          },
        })
      }
    )
    }
  }

  const getECBookingFromStrapi = () => {
    return (dispatch: Function, getState: Function) => {
      Api.getStrapiApiCall(
        endpoints.strapi_dev.ecBooking,
        '',
        (apiRespone: any) => {
          if (apiRespone) {
            const { data } = apiRespone
            console.log('data', data)
            const ecBookingStrapi : ECBookingFromStrapi = {                        
              title: data?.attributes?.title || '',
              subtitle: data?.attributes?.subtitle || '',
              opening_soon: data?.attributes?.opening_soon || '',
              confirmation_checklist: data?.attributes?.confirmation_checklist || '',
              current_center:data?.attributes?.current_center || '',
              booking_confirmed_checklist :data?.attributes?.booking_confirmed_checklist || '',
              confirm_home_test_ride : data?.attributes?.booking_confirmed_checklist || '',

          }
            dispatch({
              type: ActionTypes.EC_BOOKING_STRAPI,
              payload: {
                ecBookingFromStrapi: ecBookingStrapi
              },
            })            
          } else {
            dispatch({
              type: ActionTypes.EC_BOOKING_STRAPI,
              payload: {
                ecBookingFromStrapi: new ECBookingFromStrapi()
              },
            })
          }
        },
        (error: any) => {
          dispatch({
            type: ActionTypes.EC_BOOKING_STRAPI,
            payload: {
              ecBookingFromStrapi: new ECBookingFromStrapi()
            },
          })
        }
      )
    }
  }

  const onClickViewDetails = (visitID: string, history: any) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          rescheduleVisitID: visitID
        }
      })
      getECBookingDetail(visitID)
      history.push('/reschedule-ec-booking')    
    }
  }

  const bookingDetailsBackBtnClicked = () => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          confirmBtnEnterDetails: false
        }
      })
    }
  }

  const enterDetailsConfirmBtnClicked = (history: any) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          confirmBtnEnterDetails: true
        }
      })
      const userToken = getUserToken()
      !userToken ? history.push('/login') : history.push('/ec-booking')   
    }
  }

  const enterDetailsBackBtnClicked = () => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          continueBtn: false,
          shareLocation:false,
          shareFlow:false,
          TestRideAtHome: false,
          TestRideAtMatterSpace:false
        }
      })
    }
  }

  const pinCodeEntered = (value:string) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          pinCodeValue:value
        }
      })
    }
  }

  const setSelectedDateECBooking = (day: string, date: number, month: string) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          selectedDate: date,
          selectedDay: day,
          selectedMonth: month
        }
      })
    }
  }

  const setSelectedSlotECBooking = (slot: string, time: string) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          selectedSlot: slot,
          selectedTime: time
        }
      })
    }
  }

  const continueBtnClicked = (singleDealer: any) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          continueBtn: true,
          
        }
      })
    }
  }
  const DealerDetails=(singleDealer: any)=>{
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          singleDealer: singleDealer,
          
        }
      })
    } 
  }

  // const TestRideAtHome=(clicked:Boolean)=>{
  //   return (dispatch: Function, getState: Function) => {      
  //     dispatch({
  //       type: ActionTypes.EC_BOOKING,
  //       payload: {
  //         layoutClicked: clicked,
        
  //       }
  //     })
  //   } 
  // }
  const isDealerFound=(value:boolean)=>{
    return (disptach:Function,getState: Function)=>{
      disptach(
        {
          type: ActionTypes.EC_BOOKING,
          payload: {
            dealerFound:value
          }
        }
      )
    }
  }
  const matterSpaceTabClicked=(history:any)=>{
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          TestRideAtMatterSpace:true,
          TestRideAtHome:false
        }
      })
    } 
  }
  const LocationSharing=(value:boolean)=>{
    return (dispatch:Function,getState: Function)=>{
      dispatch({
        type:ActionTypes.EC_BOOKING,
        payload:{
          shareLocation:value
        }
      })
    }
  }
  const LocationPermissionStatus=(value:boolean)=>{
    return (dispatch:Function,getState: Function)=>{
      dispatch({
        type:ActionTypes.EC_BOOKING,
        payload:{
          LocationErrorPage: !value,
        }
      })
    }

  }
  const usersAddress=(lat:number,long:number,city:string,postalCode:string,State:string,country:string)=>{
    return (dispatch:Function,getState: Function)=>{
      dispatch({
        type:ActionTypes.EC_BOOKING,
        payload:{
          userLocationAddress:{
            lat,
            long,
            city,
            postalCode,
            State,
            country
          }
        }
      })
    }
  }
  const HomeTabClicked=(history:any)=>{
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          TestRideAtMatterSpace:false,
          TestRideAtHome:true
        }
      })
    } 
  }


  const onClickMyBookings = (history: any) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          continueRescheduleBtn: false,
          confirmRescheduleBtnEnterDetails: false
        }
      })
      history.push('/my-visits')  
    }
  }

  const rescheduleEnterDetailsBackBtnClicked = () => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          continueRescheduleBtn: false
        }
      })
    }
  }

  const resetReschedule = () => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          continueRescheduleBtn: false,
          errorPageReschedule: false,
          confirmRescheduleBtnEnterDetails: false
        }
      })
    }
  }

  const setSelectedDateRescheduleBooking = (day: string, date: number, month: string) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          selectedDate: date,
          selectedDay: day,
          selectedMonth: month
        }
      })
    }
  }

  const setSelectedSlotRescheduleBooking = (slot: string, time: string) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          selectedSlot: slot,
          selectedTime: time
        }
      })
    }
  }

  const onClickExplore = (history: any) => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          errorPageReschedule: false,
          continueRescheduleBtn: false
        }
      })
      history.push('/reschedule-ec-booking')
      window.location.reload()
    }
  }

  const onClickContinue = () => {    
    return (dispatch: Function, getState: Function) => {      
      dispatch({
        type: ActionTypes.EC_BOOKING,
        payload: {
          continueRescheduleBtn: true
        }
      })
    }
  }
  const setErrorMessageEcbooking=(errormsg:boolean)=>{
    return (dispatch:Function,getState:Function)=>{
      dispatch({
        type:ActionTypes.EC_BOOKING,
        payload:{
          errormsg:errormsg
        }
      })
    }
  }
  const setconfirmLocationBtn=(confirmBtn:boolean)=>{
    return (dispatch:Function,getState:Function)=>{
      dispatch({
        type:ActionTypes.EC_BOOKING,
        payload:{
          confirmLocationbtn:confirmBtn
        }
      })
    }

  }
  const setLoadingResponse=(loading:boolean)=>{
    return (dispatch:Function,getState:Function)=>{
      dispatch({
        type:ActionTypes.EC_BOOKING,
        payload:{
          loadResponse:loading
        }
      })
    }

  }

  const addUserDetailsTestRide = (payload: any, success: Function, fail: Function) => {
    return async (dispatch: Function, getState: Function) => {
        console.log('Before API Call');
  
        const headers = {
            'x-functions-key': process.env.STRAPI_ENV ? 'Cx9a6tKM1SmDw0p_Id0mGC4b_wota4VoxZiyGlU4RSMJAzFufy2OGA==' 
              : '1TL2D08asX4SudYJNpbeee95G1ihBSk4XcAcDocbzhlCAzFuVwBiBg==',
        };
  
        const url = process.env.STRAPI_ENV ? 'https://matter-backend-integration-service-qa.azurewebsites.net/api/unverifiedTestRide' :
         'https://matter-backend-integration-service.azurewebsites.net/api/unverifiedTestRide';
  
        try {
            const response = await axios.post(url, payload, { headers });
            console.log("response from location saving", response);
            const status = response.status;
  
            if (status === API_SUCCESS_CODE.success) {
                success(response.data);
  
                // dispatch({
                //     type: ActionTypes.EC_BOOKING,
                //     payload: {
                //         confirmLocationbtn: true,
                //     },
                // });
            } else {
                // dispatch({
                //     type: ActionTypes.EC_BOOKING,
                //     payload: {
                //         LocationErrorPage: true,
                //     },
                // });
            }
        } catch (error) {
            console.error('Error during location saving:', error);
            fail(error);
  
            // dispatch({
            //     type: ActionTypes.EC_BOOKING,
            //     payload: {
            //         LocationErrorPage: true,
            //     },
            // });
        }
    };
  };

  const getLatLngFromPincodeOSM = (payload: any, success: Function, fail: Function) => {
    return async (dispatch: Function, getState: Function) => {        
  
        const url = `https://nominatim.openstreetmap.org/search?postalcode=${payload}&format=json`;
         
        try {
            const response = await axios.get(url, payload);
            if(response.data) {
              dispatch({
                type: ActionTypes.EC_BOOKING,
                payload: {
                  ecLat: response.data[0].lat,
                  ecLon: response.data[0].lon,
                },
              });
            }
                        
        } catch (error) {
            console.error('Error while getting location:', error);
            fail(error);
        }
    };
  };

  const postUserLocation = (payload: any, success: Function, fail: Function) => {
    return async (dispatch: Function, getState: Function) => {
        console.log('Before API Call');

        const headers = {
            aesKey: '75f23efdc0eea935816cf011f78a0eb0',
            'x-functions-key': 'kJbkTGixAVgqLujJKvBMB-uNDh1Rh-rzaPh2yQ1RbgwUAzFuvuGpHA==',
        };

        const url = 'https://matter-backend-integration-service.azurewebsites.net/api/saveUserLocation';

        try {
            const response = await axios.post(url, payload, { headers });
            console.log("response from location saving", response);
            const status = response.status;
            console.log("status of response is " + status);

            if (status === API_SUCCESS_CODE.success) {
                success(status);

                dispatch({
                    type: ActionTypes.EC_BOOKING,
                    payload: {
                        confirmLocationbtn: true,
                    },
                });
            } else {
                dispatch({
                    type: ActionTypes.EC_BOOKING,
                    payload: {
                        LocationErrorPage: true,
                    },
                });
            }
        } catch (error) {
            console.error('Error during location saving:', error);
            fail(error);

            dispatch({
                type: ActionTypes.EC_BOOKING,
                payload: {
                    LocationErrorPage: true,
                },
            });
        }
    };
};

   
  const shareFLowenabled=(shareflow:boolean)=>{
    return (dispatch:Function,getState:Function)=>{
      dispatch({
        type:ActionTypes.EC_BOOKING,
        payload:{
          shareFlow:shareflow
        }
      })
    }

  }
export default {
  getDealersForHomeTestRidePin,
  LocationPermissionStatus,
  shareFLowenabled,
  setconfirmLocationBtn,
  setErrorMessageEcbooking,
  usersAddress,
  LocationSharing,
  confirmBookingDetailsAction,
  matterSpaceTabClicked,
  HomeTabClicked,
  pinCodeEntered,
  isDealerFound,
  DealerDetails,
  postDealerList,
  getDealerList,
  postUserLocation,
  postECBooking,
  getECBookings,
  putECBooking,
  getECBookingDetail,
  getECBookingFromStrapi,
  onClickViewDetails,
  bookingDetailsBackBtnClicked,
  enterDetailsConfirmBtnClicked,
  enterDetailsBackBtnClicked,
  setSelectedDateECBooking,
  setSelectedSlotECBooking,
  continueBtnClicked,
  onClickMyBookings,
  rescheduleEnterDetailsBackBtnClicked,
  setSelectedDateRescheduleBooking,
  setSelectedSlotRescheduleBooking,
  onClickExplore,
  onClickContinue,
  setLoadingResponse,
  addUserDetailsTestRide,
  resetReschedule,
  getLatLngFromPincodeOSM
}
