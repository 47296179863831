import React, { ReducerAction, useEffect } from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import HeadlessComponent from '../../components/HeadlessComponent'
import Api from '../../api'
import { endpoints } from '../../api/endPoints'
import { useDispatch, useSelector } from 'react-redux'
import homeActions from '../../actions/homeActions'
import { ReducerModel } from '../../reducers/reducerModel'
import MarkDown from '../../components/Markdown'

const TestRideTnc = () => {
    const dispatch=useDispatch()
    const {Testridetncpolicy}=useSelector((store: ReducerModel)=>store.home);
    useEffect(()=>{(
        dispatch(homeActions.getTestridetncPolicy()))
    },[]);
    console.log("test ride tnc",Testridetncpolicy)
    return (
    <div className="legal-cover">
      <Header />
      <div className="legal-content">
        <h1 className="visuallyHidden">{Testridetncpolicy.title}</h1>
        <HeadlessComponent
          title="Terms and Conditions | MATTER"
          conicalLink="https://www.matter.in/terms-and-conditions/"
          description="Explore the detailed terms and conditions for MATTER. Understand the online pre-booking, rights, responsibilities, and our policies for electric bike services."
        />
          
        <h2 className="heading monument mb-20 mb-xs-30">Terms and Conditions</h2>
        <div className="TestRideTnCTitle" style={{marginBottom: '54px'}}>{Testridetncpolicy.title}</div>

        <p>
          {<MarkDown string={Testridetncpolicy.Introduction} />}
        </p>
        <br />
        <div className='testridetnc'>
        <ol>
          <li className="monu-count">
            <h5 className="monument mb-20">{Testridetncpolicy.riderQualification_title}</h5>
            <ol>
            
            {Testridetncpolicy.riderQualification
                .split('\n\n')
                .map((paragraph, index) => (
                  <li key={index}>
                    <MarkDown string={paragraph} />
                  </li>
                ))
              }
             
            </ol>
            
          </li>

          <li className="monu-count">
            <h5 className="monument mb-20">{Testridetncpolicy.usageterms_title}</h5>
            <ol>
            
            {Testridetncpolicy.usageterms
                .split('\n\n')
                .map((paragraph, index) => (
                  <li key={index}>
                    <MarkDown string={paragraph} />
                  </li>
                ))
                
              }
             
            </ol>
            
          </li>

          <li className="monu-count">
            <h5 className="monument mb-20">{Testridetncpolicy.htrfacility_title}</h5>
            <ol>
            
            {Testridetncpolicy.htrfacility
                .split('\n\n')
                .map((paragraph, index) => (
                  <li key={index}>
                    <MarkDown string={paragraph} />
                  </li>
                ))
              }
             
            </ol>
            
          </li>
          
          <li className="monu-count">
            <h5 className="monument mb-20">{Testridetncpolicy.RiderResponsibility_title}</h5>
            <ol>
            
            {Testridetncpolicy.RiderResponsibility
                .split('\n\n')
                .map((paragraph, index) => (
                  <li key={index}>
                    <MarkDown string={paragraph} />
                  </li>
                ))
              }
             
            </ol>
            
          </li>

          <li className="monu-count">
            <h5 className="monument mb-20">{Testridetncpolicy.damagesorloss_title}</h5>
            <ol>
            
            {Testridetncpolicy.damagesorloss
                .split('\n\n')
                .map((paragraph, index) => (
                  <li key={index}>
                    <MarkDown string={paragraph} />
                  </li>
                ))
              }
             
            </ol>
            
          </li>

          <li className="monu-count">
            <h5 className="monument mb-20">{Testridetncpolicy.indemnity_title}</h5>
            <ol>
            
            {Testridetncpolicy.indemnity
                .split('\n\n')
                .map((paragraph, index) => (
                  <li key={index}>
                    <MarkDown string={paragraph} />
                  </li>
                ))
              }
             
            </ol>
            
          </li>

          <li className="monu-count">
            <h5 className="monument mb-20">{Testridetncpolicy.disputeResolution_title}</h5>
            <ol>
            
            {Testridetncpolicy.disputeResolution
                .split('\n\n')
                .map((paragraph, index) => (
                  <li key={index}>
                    <MarkDown string={paragraph} />
                  </li>
                ))
              }
             
            </ol>
            
          </li>

          <li className="monu-count">
            <h5 className="monument mb-20">{Testridetncpolicy.dataProtection_title}</h5>
            <ol>
            
            {Testridetncpolicy.dataProtection
                .split('\n\n')
                .map((paragraph, index) => (
                  <li key={index}>
                    <MarkDown string={paragraph} />
                  </li>
                ))
              }
             
            </ol>
            
          </li>
        </ol>
        </div>
          


        

      </div>
      <Footer />
    </div >
  )
}

export default TestRideTnc;
