import React from 'react'
import moment from 'moment'
import CryptoJS from 'crypto-js'
import { BIKE_MODEL_NAME, OrderDetails, UserDetails } from '../constants/model'
import { AppData } from '../constants'
import { round } from 'lodash'


async function detectIncognito() {
  return new Promise((resolve, reject) => {
    function __callback(isPrivate) {
      resolve(isPrivate);
    }

    // Firefox detection 
    function isFirefox() {
      return (
        document.documentElement !== undefined &&
        (document.documentElement.style.MozAppearance !== undefined) &&
        assertEvalToString(37)
      );
    }

    function firefoxPrivateTest() {
      // Firefox private browsing detection
      __callback(navigator.serviceWorker === undefined);
    }

    function assertEvalToString(value) {
      return value === eval.toString().length;
    }

    // Chrome and Edge detection  for private mode
    function storageQuotaChromePrivateTest() {
      if (navigator.webkitTemporaryStorage) {
        navigator.webkitTemporaryStorage.queryUsageAndQuota(
          (usedBytes, quotaBytes) => {
            const quotaInMib = Math.round(quotaBytes / (1024 * 1024));
            const quotaLimitInMib = Math.round(1073741824 / (1024 * 1024)) * 2;
            __callback(quotaInMib < quotaLimitInMib);
          },
          () => __callback(true) // Fallback to private mode if error occurs
        );
      } else {
        reject(new Error('Storage API not available.'));
      }
    }

    function oldChromePrivateTest() {
      const fs = window.webkitRequestFileSystem;
      if (fs) {
        fs(0, 1, () => __callback(false), () => __callback(true));
      } else {
        reject(new Error('Filesystem API not available.'));
      }
    }

    function isChrome() {
      const v = navigator.vendor;
      return v && v.indexOf('Google') === 0;
    }

    function isEdge() {
      return navigator.userAgent.indexOf("Edg") !== -1;
    }

    // Main function to detect incognito/private modes for Chrome, Edge, and Firefox
    function main() {
      if (isFirefox()) {
        firefoxPrivateTest();
      } else if (isChrome() || isEdge()) {
        if (self.Promise && self.Promise.allSettled) {
          storageQuotaChromePrivateTest();
        } else {
          oldChromePrivateTest();
        }
      } else {
        reject(new Error('Browser detection failed.'));
      }
    }

    main();
  });
}

async function updateFavicon() {
  const favicon = document.getElementById('favicon');

  try {
    const isIncognito = await detectIncognito();
    const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

    // Firefox private browsing 
    if (navigator.userAgent.indexOf("Firefox") !== -1 && isIncognito) {
      // Detect if the user is in system dark/light mode or using a custom Firefox theme
      const firefoxTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
      
      // Case 1: Firefox System Default + Black Tab Bar in Private Browsing
      if (firefoxTheme) {
        favicon.href = "../../public/assets/favicon-dark.ico"; // Private browsing with black tab bar (dark theme)
      } else {
        favicon.href = "../../public/assets/favicon-dark.ico"; // Private browsing with light theme (white tab bar)
      }
    }
    // Chrome and Edge private browsing: Always use favicon-dark.ico
    else if (isIncognito) {
      favicon.href = "../../public/assets/favicon-dark.ico";
    }
    // Regular browsing: Switch favicon based on theme
    else {
      favicon.href = isDarkMode
        ? "../../public/assets/favicon-dark.ico"   // Dark mode
        : "../../public/assets/favicon-light.ico"; // Light mode
    }
  } catch (error) {
    console.error('Error detecting incognito mode:', error);
    
    favicon.href = "../../public/assets/favicon-dark.ico";
  }
}

// Event listener 
window.matchMedia("(prefers-color-scheme: dark)").addEventListener('change', updateFavicon);


window.addEventListener('load', updateFavicon);




const fallbackCopyTextToClipboard = (str, callback) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  try {
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false
    el.select()
    document.execCommand('copy')
    callback(true)
    document.body.removeChild(el)
    if (selected) {
      document.getSelection().removeAllRanges()
      document.getSelection().addRange(selected)
    }
  } catch (err) {
    // something went wrong
  }
}

const copyTextToClipboard = (text, callback) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, callback)
    return
  }
  navigator.clipboard.writeText(text).then(
    () => {
      callback(true)
    },
    (err) => {
      console.error('Async: Could not copy text: ', err)
    }
  )
}

const highlighted = (v) => <span> {v ?? 0} </span>

const countdown = (ts, isOwn = false) => {
  const now = new Date().getTime()
  const timestamp = +new Date(ts)
  let delta = (timestamp - now) / 1000
  if (timestamp < now) {
    return isOwn ? '' : 'Waiting for the host'
  }
  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60
  return (
    <span>
      Event starts in
      <span className="number_highlight">
        {days > 0 ? highlighted(days) : highlighted()}
      </span>
      days
      <span className="number_highlight">
        {hours > 0 ? highlighted(hours) : highlighted()}
      </span>
      hours
      <span className="number_highlight">
        {minutes > 0 ? highlighted(minutes) : highlighted()}
      </span>
      minutes
    </span>
  )
}

const formatTime = (timestamp, scheduled = false, short = false) => {
  if (scheduled) {
    // const currentTime = +new Date();
    // const scheduledTimestamp = +new Date(timestamp);
    const scheduledTime = moment(timestamp).format('hh:mm A')
    const scheduledDate = moment(timestamp).format('DD MMMM, YYYY')
    return `${scheduledDate} at ${scheduledTime}`
  }
  if (timestamp) {
    if (!short) {
      return moment(timestamp).fromNow()
    }
    moment.locale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'seconds',
        ss: '%ss',
        m: 'a minute',
        mm: '%dm',
        h: 'an hour',
        hh: '%dh',
        d: 'a day',
        dd: '%dd',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
      },
    })
    return moment(timestamp).fromNow()
  }
  return ''
}

const formatDate = (timestamp) => {
  const REFERENCE = moment()
  const TODAY = REFERENCE.clone().startOf('day')
  const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day')
  const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day')
  const messageDate = moment(timestamp)

  if (messageDate.isSame(TODAY, 'd')) {
    return 'Today'
  }
  if (messageDate.isSame(YESTERDAY, 'd')) {
    return 'Yesterday'
  }
  if (messageDate.isAfter(A_WEEK_OLD)) {
    return moment(timestamp).format('dddd')
  }

  return moment(timestamp).format('DD/MM/yyyy')
}

function connect(div1, div2) {
  function getOffset(el) {
    const rect = el.getBoundingClientRect()
    return {
      left: rect.left + window.pageXOffset,
      top: rect.top + window.pageYOffset,
      width: rect.width || el.offsetWidth,
      height: rect.height || el.offsetHeight,
    }
  }
  const off1 = getOffset(div1)
  const off2 = getOffset(div2)

  const x1 = off1.left + off1.width
  const y1 = off1.top + off1.height

  const x2 = off2.left + off2.width
  const y2 = off2.top

  const length = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1))
  const cx = (x1 + x2) / 2 - length / 2
  const cy = (y1 + y2) / 2 - 1 / 2
  const angle = Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI)

  return {
    cx: `${cx}px`,
    cy: `${cy}px`,
    length: `${length}px`,
    angle: `${angle}deg`,
  }
}

function annotate(number, maxPlaces, forcePlaces, abbr) {
  let rounded = 0
  switch (abbr) {
    case 'T':
      rounded = number / 1e12
      break
    case 'B':
      rounded = number / 1e9
      break
    case 'M':
      rounded = number / 1e6
      break
    case 'K':
      rounded = number / 1e3
      break
    case '':
      rounded = number
      break
    default:
      rounded = number
      break
  }
  if (maxPlaces !== false) {
    const test = new RegExp(`\\.\\d{${maxPlaces + 1},}$`)
    if (test.test(`${rounded}`)) {
      rounded = rounded.toFixed(maxPlaces)
    }
  }
  if (forcePlaces !== false) {
    rounded = Number(rounded).toFixed(forcePlaces)
  }
  return rounded + abbr
}

function abbreviate(num, maxPlaces, forcePlaces, letterForced) {
  const number = Number(num)
  const forceLetter = letterForced || false
  if (forceLetter !== false) {
    return annotate(number, maxPlaces, forcePlaces, forceLetter)
  }
  let abbr
  if (number >= 1e12) {
    abbr = 'T'
  } else if (number >= 1e9) {
    abbr = 'B'
  } else if (number >= 1e6) {
    abbr = 'M'
  } else if (number >= 1e3) {
    abbr = 'K'
  } else {
    abbr = ''
  }
  return annotate(number, maxPlaces, forcePlaces, abbr)
}
const setUserToken = (data: UserDetails) => {
  const userDetails = getLocalUserDetails();
  localStorage.setItem('authToken', JSON.stringify({...userDetails, userToken : data.userToken, phone: data.phone}))
}

const setPartnerToken = (token: string) => {
  const userDetails = getLocalUserDetails();
  localStorage.setItem('authToken', JSON.stringify({ ...userDetails, partnerToken : token }))
}



const setLocalStrapiToken = (token: string) => {
  const userDetails = getLocalUserDetails();
  return localStorage.setItem('authToken', JSON.stringify({...userDetails,strapiToken : token}))
}

const getPartnerToken = () => {
  const authToken = getLocalUserDetails();
  return (authToken && authToken.partnerToken) || ''
}

const getLocalStrapiToken = () => {
  const authToken = getLocalUserDetails();
  return (authToken && authToken.strapiToken) || ''
}

const getLocalUserDetails = () => {
  const userDetails = localStorage.getItem('authToken')
  if (userDetails) {
    const user = JSON.parse(userDetails)
    return user || {}
  }
}

const getUserToken = () => {
  const userDetails = localStorage.getItem('authToken')
  if (userDetails) {
    const user = JSON.parse(userDetails)
    if (user && user.userToken) {
      return user.userToken
    } else {
      return ''
    }
  }
  return ''
}

const getUserPhone = () => {
  const userDetails = localStorage.getItem('authToken')
  if (userDetails) {
    const user = JSON.parse(userDetails)
    if (user && user.phone) {
      return user.phone
    } else {
      return ''
    }
  }
  return ''
}



function generateOrdinal(number: number) {
  switch (number) {
    case 1:
    case 21:
      return number + 'st'
      break
    case 2:
    case 22:
      return number + 'nd'
      break
    case 3:
    case 23:
      return number + 'rd'
      break
    default:
      return number + 'th'
  }
}

function numberWithCommas(amount: string) {
  return parseInt(amount).toLocaleString('en-IN', {
    maximumFractionDigits: 2,
  })
}

const getSelectedBikeByColor = (item: OrderDetails) => {
  switch (item.bikeModelName) {
    case BIKE_MODEL_NAME.AERA_5000:
      return AppData.aera5000AvailableColor.find((availBikeColor) =>
        availBikeColor.colorName
          .toLowerCase()
          .includes(item.bikeColor.toLowerCase())
      )
    case BIKE_MODEL_NAME.AERA_5000_PLUE:
      return AppData.aera5000PlusAvailableColor.find((availBikeColor) =>
        availBikeColor.colorName
          .toLowerCase()
          .includes(item.bikeColor.toLowerCase())
      )
  }
}

const generateRandomDeviceId = (length: number) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

const getDefaultLocationIndex = (locationList: Array<any>,selectedCityState ?: string) => {
  const defaultBangloreIndex = locationList.findIndex(
    // (item) => item.city.toLowerCase() == (selectedCity ? selectedCity.toLowerCase() :  'Bengaluru'.toLowerCase())
    (item) => item.state.toLowerCase() == (selectedCityState ? selectedCityState.toLowerCase() :  'Karnataka'.toLowerCase())
  )
  return defaultBangloreIndex
}

function parseJwt(token : string) {
  try {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(jsonPayload)
  } catch (error) {
    return {}
  }
}

const setXResponse = (value: string) => {
  localStorage.setItem('x-response', value);
  window.dispatchEvent(new Event("storage"));
}

const getXResponse = () => {
  const xResponse = localStorage.getItem('x-response')
  if (xResponse) {
    return xResponse || ''
  }
}



const handlePasteMethod = (e: any, setFieldValue: any , fieldName?:string) => {
  setTimeout(function () {
    setFieldValue(fieldName, e.target.value.slice(0, 10))
  }, 0)
}

const tagMethodGTM = (eventType: any , category:any , action:any , label:any,customPair?: any) => {

 
  
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];

  let eventDetails = {
    'event': eventType,
    'eventCategory': category ,
    'eventAction': action,
    'eventLabel': label,
  }
  if (customPair) {
    eventDetails = {...eventDetails,...customPair}
  }

  console.log('GTM Tag Click', eventDetails)
  //@ts-ignore
  window.dataLayer.push(eventDetails)
}

const moengageEvent = (
  eventName: any,
  eventAttributes: any  
) => {  
  //console.log('Moengage Event', eventName, eventAttributes)
  //@ts-ignore
  Moengage.track_event(eventName, eventAttributes);  
}

const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const r = 6371; // km
  const p = Math.PI / 180;

  const a = 0.5 - Math.cos((lat2 - lat1) * p) / 2
                + Math.cos(lat1 * p) * Math.cos(lat2 * p) *
                  (1 - Math.cos((lon2 - lon1) * p)) / 2;

  return round(2 * r * Math.asin(Math.sqrt(a)));
}

const getUserLocation = (callback: Function) => {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        callback({ latitude, longitude })
      },
      (error) => callback(null)
    )
  } else callback(null)
}

const openGoogleMap = (sourceLocation: string, destinationLocation: string) => {
  console.log(sourceLocation)

  let url

  if (sourceLocation === 'notFound') {
    url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destinationLocation
    )}`
  } else {
    url = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
      sourceLocation
    )}&destination=${encodeURIComponent(destinationLocation)}`
  }

  console.log(url)

  return url
}

const generateGoogleMapsSearchLink = (address: string)=> {
  // Encode the address for a valid URL
  let encodedAddress = encodeURIComponent(address);

  // Construct the Google Maps search URL
  let mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

  return mapsUrl;
}

const encryptDataWithKey = (data: any, phone : string) => {
  let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), phone).toString()
  return ciphertext
}

const decryptDataWithKey = (cipherText: any, phone: string) => {
  let decryptedData = ''
  try {
    if (!phone || !cipherText) {
      return ''
    }
    let bytes = CryptoJS.AES.decrypt(cipherText, phone)
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  } catch (error) {
    return decryptedData || ''
  }
  return decryptedData || ''
}


const encryptData = (data: any) => {
  const phone = getUserPhone()
  let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), phone).toString();
  return ciphertext
}

const decryptData = (cipherText: any, ) => {
  
  if (!getUserPhone() || !cipherText) {
    return {}
  }
  let bytes = CryptoJS.AES.decrypt(cipherText, getUserPhone());
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData || {}
}

export {
  getSelectedBikeByColor,
  getDefaultLocationIndex,
  numberWithCommas,
  generateOrdinal,
  getUserToken,
  setUserToken,
  generateRandomDeviceId,
  connect,
  getLocalUserDetails,
  copyTextToClipboard,
  formatTime,
  formatDate,
  abbreviate,
  countdown,
  parseJwt,
  handlePasteMethod,
  tagMethodGTM,
  getPartnerToken,
  setPartnerToken,
  getLocalStrapiToken,
  setLocalStrapiToken,
  getUserLocation,
  openGoogleMap,
  setXResponse,
  getXResponse,
  encryptData,
  decryptData,
  encryptDataWithKey,
  decryptDataWithKey,
  detectIncognito,
  updateFavicon,
  moengageEvent,
  distance,
  generateGoogleMapsSearchLink
}
